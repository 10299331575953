import _ from "lodash";
import React, { Component } from 'react';
import { data_fetch_api_resource, data_download_api_resource } from "../../utils/http_functions";
import { Chart } from '../Chart';
import { i18n, dayjs } from "../../config";
import { regional_settings } from "../../constants"

import { GetApp } from "@material-ui/icons"

import {
  Button, CircularProgress, MenuItem,
  Select, FormControl, InputLabel, Grid
} from "@material-ui/core"

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import saveAs from 'file-saver';

class CCHChart extends Component {
  constructor(props) {
    super(props);

    const startDate = dayjs().date(1);

    const endDate = dayjs();
    endDate.month(endDate.month() + 1);
    endDate.date(0);

    this.state = {
      chart_spec_url: `contracts/${this.props.contractId}/cch/chart/monthly/`,
      chart_loading: true,
      chart_downloading: false,
      chart_rechart_data: null,
      chart_type: 'monthly',
      chart_start: startDate,
      chart_start_value: startDate,
      chart_end: endDate,
      chart_end_value: endDate
    };
  }

  handleChange = (event, index, value) => {
    let timing = event.target.value;
    let spec_url = this.getSpecUrl(timing);
    this.setState({
      chart_type: timing,
      chart_spec_url: spec_url,
      chart_rechart_data: null,
    });
    this.fetchChartSpec(spec_url);
  };

  handleStartChange = (event, value) => {
    this.setState({ chart_start_value: event });
    if (!!event && event.isValid()) {
      const startDateDate = event;
      this.setState({ chart_start: startDateDate });
      const startDateISO = startDateDate.toISOString();
      const endDateISO = this.state.chart_end.toISOString();
      const spec_url = `contracts/${this.props.contractId}/cch/chart/${this.state.chart_type}/?start=${startDateISO}&end=${endDateISO}`;
      this.fetchChartSpec(spec_url);
    }
  };

  handleEndChange = (event, value) => {
    this.setState({ chart_end_value: event });
    if (!!event && event.isValid()) {
      const endDateDate = event;
      this.setState({ chart_end: endDateDate });
      const startDateISO = this.state.chart_start.toISOString();
      const endDateISO = endDateDate.toISOString();
      const spec_url = `contracts/${this.props.contractId}/cch/chart/${this.state.chart_type}/?start=${startDateISO}&end=${endDateISO}`;
      this.fetchChartSpec(spec_url);
    }
  };

  getSpecUrl(timing) {
    let spec_url = `contracts/${this.props.contractId}/cch/chart/${timing}/`;
    if (timing !== 'monthly') {
      const startDate = this.state.chart_start.toISOString();
      const chart_end = dayjs().set({
        year: this.state.chart_start.year(),
        month: this.state.chart_start.month() + 1,
        date: 0
      });
      this.setState({ chart_end });
      const endDate = chart_end.toISOString();
      spec_url = `contracts/${this.props.contractId}/cch/chart/${timing}/?start=${startDate}&end=${endDate}`;
    }
    return spec_url
  }

  downloadCCH = () => {
    const startDate = this.state.chart_start.toISOString();
    const endDate = this.state.chart_end.toISOString();
    this.setState({ chart_downloading: true });
    const url = `contracts/${this.props.contractId}/cch/download/?start=${startDate}&end=${endDate}`;
    data_download_api_resource(this.props.token, url, 1)
      .then(response => {
        const filename = response.headers["content-disposition"].split("=");
        saveAs(response.data, filename[1]);
      })
      .finally(() => {
        this.setState({ chart_downloading: false });
      })
  };

  fetchChartSpec(spec_url) {
    // We will still try to request to V2 in an hourly graph, but then endpoint will not answer properly
    // hence we will fallback to V1 which should work as always.
    if (spec_url) {
      this.setState({ chart_loading: true });
      data_fetch_api_resource(this.props.token, spec_url, 2)
        .then((response) => {
          if (response.status === 200 && !_.isEmpty(response.data)) {
            this.setState({ chart_rechart_data: response.data });
          } else {
            this.setState({ chart_rechart_data: null });
          }
        }).finally(() => {
          this.setState({ chart_loading: false });
        });
    }
  }

  componentDidMount() {
    this.fetchChartSpec(this.state.chart_spec_url);
    //this.setState({chart_rechart_data: ['empty'], chart_loading: false});
  }

  componentDidUpdate(prevProps) {
    if (this.props.contractId != prevProps.contractId) {
      let spec_url = this.getSpecUrl(this.state.chart_type)
      this.fetchChartSpec(spec_url);
    }
  }

  parseChartData(contracts) {
    //{date: 'yyyy-MM-dd', period: String, activa: Double, reactiva: Double, exportada: Double}
    // this.state.chart_type

    let data = [];
    let periods = [];
    let components = {};
    const energyTypes = [
      { devName: 'activa', renderName: 'Activa ' },
      { devName: 'exportada', renderName: 'Exportada ' },
      { devName: 'reactiva', renderName: 'Reactiva ' },
    ];
    let renderedEnergyTypes = [];
    const number_of_months = 12;

    const current_year = dayjs().year();
    let init_year = current_year;
    //find the earliest year
    contracts.forEach((contract) => {
      let contract_year = dayjs(contract.date).year();

      if (contract_year < init_year) {
        init_year = contract_year;
      }
    })

    if (this.state.chart_type === 'monthly') {
      let energy_per_year = {};
      for (let year = init_year; year <= current_year; year++) {
        energy_per_year[year] = [];

        // Assign the 12 months
        for (let i = 0; i < number_of_months; i++) {
          energy_per_year[year].push({ 'name': i, 'total': 0 });
        }
      }

      contracts.forEach((contract) => {
        const end_date = dayjs(contract.date);

        // Extract the date
        const month = end_date.month();
        const year = end_date.year();
        const year_lite = year - 2000;

        //const energy = (parseFloat(contract.consumption));

        let energy = {};

        energyTypes.forEach(energyType => {
          if (contract[energyType.devName] && contract[energyType.devName] > 0) {
            energy[energyType.renderName] = contract[energyType.devName];
            !renderedEnergyTypes.includes(energyType.renderName) && renderedEnergyTypes.push(energyType.renderName);
          }
        });

        const period = contract.period;

        if (period && !periods.includes(period)) {
          //make sure all periods are saved for the component var (used in the graph legend)
          periods.push(period);
        }

        let titlePeriod = (period ?? '');
        // Set the energy and amount


        for (const [energyName, energyValue] of Object.entries(energy)) {
          energy_per_year[year][month][energyName + titlePeriod] = energyValue;
        }

        // Override title by default by shorted month and the year
        energy_per_year[year][month]['name'] = i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
      });

      if (periods.length > 0) {
        periods.forEach((period) => {
          renderedEnergyTypes.forEach(
            energyType => components[energyType + period] = { 'title': energyType + period }
          );
        });
      } else {
        renderedEnergyTypes.forEach(
          energyType => components[energyType] = { 'title': energyType }
        );
      }

      let final_energy = [];
      for (let year = init_year; year <= current_year; year++) {
        for (let month = 0; month < number_of_months; month++) {
          //Select just non-empty elements
          energy_per_year[year][month].total = 0;
          // Calculate totals for each month
          _.forEach(Object.keys(energy_per_year[year][month]), (k) => {
            if (k !== 'total' && k !== 'name') {
              energy_per_year[year][month].total += energy_per_year[year][month][k];
            }
          });

          // Format decimals
          if (typeof energy_per_year[year][month].name === 'string') {
            energy_per_year[year][month].total = Number(energy_per_year[year][month].total).toFixed(0);
            const the_energy = Object.assign({}, energy_per_year[year][month]);
            final_energy.push(the_energy);
          }
        }
      }
      data = final_energy;
    } else if (this.state.chart_type === 'daily' || this.state.chart_type === 'hourly') {

      let aux_data = [];
      let energy = {};

      contracts.forEach((contract) => {

        energyTypes.forEach(energyType => {
          if (contract[energyType.devName] && contract[energyType.devName] > 0) {
            energy[energyType.renderName] = contract[energyType.devName];
            !renderedEnergyTypes.includes(energyType.renderName) && renderedEnergyTypes.push(energyType.renderName);
          }
        });

        let date = dayjs(contract.date);
        let period = this.state.chart_type === 'hourly' ? "" : contract.period;

        if (!periods.includes(period)) {
          //make sure all periods are saved for the component var (used in the graph legend)
          periods.push(period);
        }

        // Indicates whether we found the same day
        let trobat = false;

        // We group the data by days
        aux_data.forEach(el => {
          if (el.name.valueOf() === date.valueOf()) {
            for (const [energyName, energyValue] of Object.entries(energy)) {
              (el[energyName + period] === undefined) ?
                el[energyName + period] = energyValue : el[energyName + period] += energyValue;
            }
            trobat = true;
          }
        });

        if (!trobat) {
          let newAuxData = {
            name: date,
          }

          for (const [energyName, energyValue] of Object.entries(energy)) {
            newAuxData[energyName + period] = energyValue;
          }

          aux_data.push(newAuxData)
        }
      })

      periods.forEach((period) => {
        for (const [energyName, energyValue] of Object.entries(energy)) {
          components[energyName + period] = { 'title': energyName + period }
        }
      });

      aux_data.sort(function (a, b) { return (a.name - b.name); });
      aux_data.forEach(el => {
        const date = dayjs(el.name);
        const day = date.date();
        const month = date.month();
        const year = date.year();
        const year_lite = year - 2000;
        //we compose the date as a readable thing like 12'Ene'22 or 3:00-12'Ene
        if (this.state.chart_type === 'hourly') {
          const hour = date.hour();
          el.name = hour + "h-" + day;
        } else {
          el.name = day + "'" + i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
        }

        //we add all the consumptions into the total
        _.forEach(Object.keys(el), (k) => {
          el.total = 0;
          if (k !== 'total' && k !== 'name') {
            el.total += el[k];
          }
        });

      });
      data = aux_data;
    }

    return {
      data,
      components,
    }
  }

  render() {
    const download_icon = this.state.chart_downloading ? <CircularProgress size={20} thickness={3} /> : <GetApp />;

    const unit = regional_settings.energy_unit;

    const chartData =
      !_.isEmpty(this.state.chart_rechart_data) ?
        this.parseChartData(this.state.chart_rechart_data) : [];

    const canDownloadHourlyCCH = this.state.chart_type === 'hourly' && chartData.data;

    // Change this to adaptDayJS when using MUI5
    const langDateFormat = {'es': "DD/MM/YYYY", 'ca': "DD/MM/YYYY", 'en': "MM/DD/YYYY"}

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControl>
            <Grid container alignItems="center" spacing={4}>
              <Grid item>
                <InputLabel>
                  {i18n.t('common:text.cch_visualization')}
                </InputLabel>
                <Select
                  onChange={this.handleChange}
                  value={this.state.chart_type}
                >
                  <MenuItem key="monthly" value="monthly">{i18n.t('common:text.cch_monthly')}</MenuItem>
                  <MenuItem key="daily" value="daily">{i18n.t('common:text.cch_daily')}</MenuItem>
                  <MenuItem key="hourly" value="hourly">{i18n.t('common:text.cch_hourly')}</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={DayJsUtils} locale={i18n.language}>
                  <Grid container spacing={4}>
                    {this.state.chart_type !== 'monthly' &&
                      <Grid item>
                        <KeyboardDatePicker
                          disableToolbar
                          error={this.state.chart_start_value == null || this.state.chart_start_value == "Invalid Date"}
                          helperText={this.state.chart_start_value == null || this.state.chart_start_value == "Invalid Date" ? i18n.t('common:text.cch_invalid_date') : ""}
                          KeyboardButtonProps={{'aria-label': 'change date'}}
                          label={i18n.t("common:text.cch_start")}
                          maxDate={this.state.chart_end}
                          format={langDateFormat[i18n.language]}
                          onChange={this.handleStartChange}
                          value={this.state.chart_start}
                          variant="inline"
                        />
                      </Grid>
                    }
                    {this.state.chart_type !== 'monthly' &&
                      <Grid item>
                        <KeyboardDatePicker
                          disableToolbar
                          error={this.state.chart_end_value == null || this.state.chart_end_value == "Invalid Date"}
                          helperText={this.state.chart_end_value == null || this.state.chart_end_value == "Invalid Date" ? i18n.t('common:text.cch_invalid_date') : ""}
                          KeyboardButtonProps={{'aria-label': 'change date'}}
                          label={i18n.t("common:text.cch_end")}
                          minDate={this.state.chart_start}
                          format={langDateFormat[i18n.language]}
                          onChange={this.handleEndChange}
                          value={this.state.chart_end}
                          variant="inline"
                        />
                      </Grid>
                    }
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              {this.state.chart_type === 'hourly' && canDownloadHourlyCCH &&
                <Button
                  variant={'contained'}
                  onClick={() => this.downloadCCH()}
                  disabled={this.state.chart_downloading}

                >
                  {download_icon}
                  {i18n.t('common:text.contract_cch_download')}
                </Button>
              }
            </Grid>
          </FormControl>
        </Grid>

        <Grid container justifyContent="center" style={{paddingTop: 20}}>
          {this.state.chart_loading &&
            <Grid item xs={12} align="center">
              <CircularProgress />
            </Grid>
          }
          {!this.state.chart_loading &&
            <Grid item xs={12}>
              {this.state.chart_rechart_data &&
                <Chart
                  data={chartData.data}
                  components={chartData.components}
                  unit={unit}
                  animated={true}
                  stacked={false}
                  lineGraph={false}
                  ignoreMaxContracts={true}
                  areaChart={this.state.chart_type === 'hourly'}
                  compoundBars={!(this.state.chart_type === 'hourly')}
                />
              }
              {!this.state.chart_rechart_data &&
                <div style={{ textAlign: "center" }}>{i18n.t('common:text.contract_cch_chart_no_data')}</div>
              }
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }
}

export default CCHChart;