import { IconButton } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { i18n } from "../../config";
import { data_download_api_resource } from "../../utils/http_functions";
import { Alert } from "@material-ui/lab";
import { useState } from "react";

const DownloadAttachmentButton = ({downloadURL}) => {
    const [hasError, setHasError] = useState(false);

    const handleDownloadAttachment = () => {
        data_download_api_resource(null, downloadURL, 2)
          .then(response => {
              const filename = response.headers["content-disposition"].split("=")[1];
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
          }).catch(() => {
            setHasError(true);
          });
      }
    const drawed = {
        true: (
            <Alert style={{cursor: "pointer"}} severity={"error"} onClick={() => setHasError(false)}>
            </Alert>
        ),
        false: (
            <IconButton edge="end" aria-label={i18n.t('common:text.attachment_download')} onClick={() => handleDownloadAttachment()}>
                <DownloadIcon />
            </IconButton>
        )
    }

    return drawed[hasError];
}

export default DownloadAttachmentButton;