import * as Sentry from '@sentry/react';

import Settings from './settings';

const initSentry = () => {
  // Added ignore to Sentry console logs as they are unreadable and useless:
  // https://github.com/getsentry/sentry-react-native/issues/794#issuecomment-908189765
  if (Settings.sentry && Settings.sentry.dsn) {
    Sentry.init({
      dsn: Settings.sentry.dsn,
      
      environment: Settings.sentry.environment,

      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: Settings?.sentry?.sessionReplays.errorSampleRate || 1.0,

      integrations: [
        Sentry.breadcrumbsIntegration({
          console: false
        }),
        Settings?.sentry?.sessionReplays.enabled && Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],
    });
  }
};

export { initSentry };
