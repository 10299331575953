import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as invoicesActionCreators from "../../actions/invoices";
import * as contractActionCreators from "../../actions/contracts";
import { debug } from "../../utils/debug";
import { regional_settings } from "../../constants";
import { locale_code } from "../../constants/i18n";
import { i18n, dayjs } from "../../config";

import { ContentHeader } from "../ContentHeader";

import { Notification } from "../Notification";
import { LoadingAnimation } from "../LoadingAnimation";
import { SmartTable } from "../SmartTable";
import StripePaymentDialog from "./StripePaymentDialog";
import SearchIcon from '@material-ui/icons/Search';
import { Grid, InputAdornment, TextField, Tooltip } from "@material-ui/core"

import { Chart } from "../Chart";
import Settings from "../../settings";
import _ from "lodash";

import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Box,
  Typography
} from "@material-ui/core";
import {
  ArrowForward,
  ArrowBack,
  GetApp,
  ErrorOutline,
} from "@material-ui/icons";

// Payment provider (default is stripe)
const paymentProvider = _.get(Settings, "paymentProvider", "stripe");

// Stripe Key
const stripeKey = _.get(Settings, "stripe.key", null);

function mapStateToProps(state) {
  return {
    data: state.invoices,
    offset: state.invoices.offset_items,
    invoice_function: state.invoices.invoice_function,
    page_items: state.invoices.page_items,
    token: state.auth.token,
    loaded: state.invoices.loaded,
    isFetching: state.invoices.isFetching,
    message_text: state.invoices.message_text,
    filterContract: state.contracts.filterContract,
    toggleLeft: false,
    download_status: state.invoices.download_status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
        invoices: bindActionCreators(invoicesActionCreators, dispatch),
        contract: bindActionCreators(contractActionCreators, dispatch),
    }
  }
}

const style = {
  buttonAdd: {
    marginRight: 20,
  },
  buttonPosition: {
    textAlign: "right",
  },
  table: {
    marginTop: 20,
  },
  chart: {
    marginBottom: 25,
  },
  aggregationsCenter: {
    display: "flex",
  },
  toggle: {
    marginTop: 7,
    marginLeft: 12,
  },
  labelToggle: {
    marginTop: 7,
    marginLeft: 7,
  },
  searchInvoices: {
    marginBottom: 20,
  }
};

class InvoicesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message_text: null,
      contractId: props.params ? props.params.contractId : null,
      dialogInvoice: null,
      toggleLeft: false,
      offset: 0,
      page_items: _.get(Settings, "invoices.itemsPerPage", 100),
      invoicesSearchTerm: ''
    };
  }

  componentDidMount() {

    // If they are filtering the invoices
    if(_.get(this.props, 'match.params.contractName', false)){

      // If they don't come from the contracts list view
      if (!_.get(this.props, 'filterContract.cups.full_address', false)) {
        this.props.actions.contract.fetchContracts(
          this.props.token, false, 0, this.props.match.params.contractName
        );
      } else {
        if ((_.isEmpty(this.props.data.items) && !this.props.loaded) ||
          _.get(this.props, 'data.items[0].contract.name', '') !== _.get(this.props, 'filterContract.name', '')) {
          this.fetchData();
        }
      }
    } else if ((_.isEmpty(this.props.data.items) && !this.props.loaded)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loaded && !prevProps.loaded) {
      const invoice = this.props.data.items[0];
      switch (this.props.contract_function) {
        case "download":
          if (
            invoice &&
            !(
              invoice.id &&
              invoice.id in this.props.download_status &&
              this.props.download_status[invoice.id]
            )
          )
            this.exportInvoiceFun(invoice.id);
          break;
        case "payment":
          //todo
          break;
        default:
          break;
      }
    }
    if (!prevProps.filterContract && this.props.filterContract) {
      if ((_.isEmpty(this.props.data.items) && !this.props.loaded) ||
      _.get(this.props, 'data.items[0].contract.name', '') !== _.get(this.props, 'filterContract.name', '')) {
        this.fetchData();
      }
    }
  }

  fetchData = (initial = true, offset = 0) => {
    const token = this.props.token;
    if (this.props.filterContract){
      this.props.actions.invoices.fetchInvoices(token, this.props.filterContract.name, initial, offset);
    } else {
      this.props.actions.invoices.fetchInvoices(token, null, initial, offset);
    }
  };

  toggleRender = (event, status) => {
    this.setState({
      toggleLeft: status,
      message_open: false,
    });
    this.animateChart = false;
  };

  refreshData = () => {
    this.fetchData(false);
    this.setState({
      message_open: true,
    });
  };

  exportInvoice = (event, invoiceID) => {
    event.preventDefault();
    this.props.actions.invoices.exportInvoice(this.props.token, invoiceID);
  };
  exportInvoiceFun = (invoiceID) => {
    this.props.actions.invoices.exportInvoice(this.props.token, invoiceID);
  };

  previous_page = () => {
    const offset = this.props.offset - this.props.page_items;
    this.setState({ offset });
    this.fetchData(false, offset);
  };

  next_page = () => {
    const offset = this.state.offset + this.state.page_items;
    this.setState({ offset });
    this.fetchData(false, offset);
  };

  chartInvoices(invoices, energy) {
    let components = {};
    const number_of_months = 12;

    const current_year = dayjs().year()
    let init_year = current_year;
    //find the earliest year
    invoices.forEach((invoice) => {
      let invoice_year = dayjs(invoice.end_date).year();

      if (invoice_year < init_year) {
        init_year = invoice_year;
      }
    });

    let energy_per_year = {};
    let amount_per_year = {};
    for (let year = init_year; year <= current_year; year++) {
      energy_per_year[year] = [];
      amount_per_year[year] = [];

      // Assign the 12 months
      for (let i = 0; i < number_of_months; i++) {
        energy_per_year[year].push({ name: i, total: 0 });
        amount_per_year[year].push({ name: i, total: 0 });
      }
    }

    invoices.forEach((invoice) => {
      const end_date = dayjs(invoice.end_date);

      // Extract the date
      const month = end_date.month();
      const year = end_date.year();
      const year_lite = year - 2000;

      const sign = invoice.rectifirectificative_type === "A" ? -1 : 1;

      const amount = parseFloat(invoice.amount_total) * sign;
      const energy = parseFloat(invoice.energy_consumed) * sign;
      const contract = invoice.contract.name;

      // Ensure initialize correctly components with all CUPS
      if (!(contract in components)) {
        components[contract] = {
          title: contract,
        };
      }

      // Set the energy and amount
      energy_per_year[year][month][contract] = energy;
      amount_per_year[year][month][contract] = amount;
      // Override title by default by shorted mont and the year
      amount_per_year[year][month]["name"] =
        i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
      energy_per_year[year][month]["name"] =
        i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
    });

    let final_amount = [];
    let final_energy = [];
    for (let year = init_year; year <= current_year; year++) {
      for (let month = 0; month < number_of_months; month++) {
        //Select just non-empty elements
        amount_per_year[year][month].total = 0;
        if (Object.keys(amount_per_year[year][month]).length > 2) {
          // Calculate totals for each month
          _.forEach(Object.keys(amount_per_year[year][month]), (k) => {
            if (k !== "total" && k !== "name") {
              amount_per_year[year][month].total +=
                amount_per_year[year][month][k];
              energy_per_year[year][month].total +=
                energy_per_year[year][month][k];
            }
          });

          // Format decimals
          amount_per_year[year][month].total = Number(
            amount_per_year[year][month].total
          ).toFixed(2);
          energy_per_year[year][month].total = Number(
            energy_per_year[year][month].total
          ).toFixed(0);

          const the_amount = Object.assign({}, amount_per_year[year][month]);
          const the_energy = Object.assign({}, energy_per_year[year][month]);

          final_amount.push(the_amount);
          final_energy.push(the_energy);
        }
      }
    }

    const data = energy ? final_energy : final_amount;

    return {
      data,
      components,
    };
  }

  handleOpenDialog = (e, invoice) => {
    e.preventDefault();
    this.setState({ dialogOpen: true, dialogInvoice: invoice });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, dialogInvoice: null });
  };

  JSON_to_arrays = (invoices) => {

    const header = [
      {
        title: null,
      },
      {
        title: i18n.t('common:text.invoices_invoice_number'),
      },
      {
        title: i18n.t('common:text.invoices_date'),
      },
      {
        title: i18n.t('common:text.invoices_period'),
      },
      {
        title: i18n.t('common:text.invoices_address'),
      },
      {
        title: i18n.t('common:text.invoices_import'),
      },
      {
        title: i18n.t('common:text.invoices_energy'),
      },
      {
        title: i18n.t('common:text.invoices_payed'),
      },
      {
        title: i18n.t('common:text.invoices_actions'),
      },
    ];

    const props = this.props;
    const that = this;
    const content = invoices.map((invoice, index) => {
      // Invoice date
      const invoice_date = dayjs(invoice.date).format('L');

      //Start and End date (period)
      const start_date = dayjs(invoice.start_date).format('L');

      const end_date = dayjs(invoice.end_date).format('L');

      const period = start_date + " > " + end_date;

      const disabled_button =
        invoice.id in props.download_status &&
        props.download_status[invoice.id];

      const download_button = (
        <Button
          title={"Download invoice"}
          color={"primary"}
          variant={"contained"}
          disabled={disabled_button}
          onClick={(e) => props.actions.invoices.exportInvoice(props.token, invoice.id)}
          style={{
            marginTop: "5px",
            marginLeft: "5px",
            marginRight: "5px",
            overflow: "hidden",
          }}
        >
          {disabled_button ? <CircularProgress size={25} /> : <GetApp />}
          {i18n.t('common:text.invoices_view_download_button')}
        </Button>
      );

      let paid = invoice.paid ? i18n.t('common:text.invoice_paid') : <strong>{i18n.t('common:text.invoice_not_paid')}</strong>;
      if (invoice.paid && invoice.rectificative_type === "A") {
        paid = <strong>{i18n.t('common:text.invoice_refunded')}</strong>;
      }
      if (invoice.amount_pending && invoice.state !== "grouped_proforma") {
        if (invoice.amount_pending != invoice.amount_total) {
          paid = <strong>{i18n.t('common:text.invoices_parcial_payment')}</strong>;
        }

        // We enable a link to show the pending state history only if there
        // is more than one item to show.
        if (
          invoice.pending_state_history &&
          invoice.pending_state_history.length > 1
        ) {
          paid = (
            <div>
              <Button
                onClick={(e) => that.handleOpenDialog(e, invoice)}
                color='primary'
                variant={'text'}
                style={{padding: 0}}
              >
                {paid}
              </Button>
            </div>
          );
        }
      }

      let payment = null;
      switch (paymentProvider) {
        case "stripe":
          if (stripeKey) {
            payment = (
              <StripePaymentDialog
                invoice={invoice}
                onPaymentSuccessful={() => this.refreshData()}
              />
            );
          }
          break;
        default:
          break;
      }

      const values = [
        invoice.amount_debt ? <ErrorOutline color='primary' /> : null,
        invoice.number,
        invoice_date,
        period,
        invoice.cups.street,
        `${
          invoice.amount_total_printable
        }`,
        `${invoice.energy_consumed.toLocaleString(locale_code)} ${
          regional_settings.energy_unit
        }`,
        paid,
        <div>
          {download_button}
          {invoice.amount_debt > 0 ? payment : null}
        </div>,
      ];

      return values;
    });

    return {
      header,
      content,
    };
  };


  handleChangeSearchTerm = (event) => {
    this.setState({
      invoicesSearchTerm: event.target.value,
    })
  }

  filterInvoices = () => {

    // Filtra per nom d'usuari, el nif, el número de la factura, l'import, la tarifa,
    // la direcció, el CUPS, la id del contracte i el nom del contracte
    let data = this.props.data.items;
    const filteredResults = data.filter((item) => {
      const filterValues = [
        item.partner.name,
        item.partner.vat,
        item.number,
        item.amount_total_printable,
        item.pricelist.name,
        item.cups.full_address,
        item.cups.street,
        item.cups.name,
        item.contract.id,
        item.contract.name,
        item.tariff.name
      ]

      return filterValues.some(item => String(item).replace(",", "").toLowerCase().includes(this.state.invoicesSearchTerm.replace(",", "").trim().toLowerCase()))
    });

    return this.JSON_to_arrays(filteredResults);
  };

  render() {
    const { data, loaded } = this.props;
    const invoices = data.items;
    const { contractId, toggleLeft } = this.state;

    let adaptedData = null;
    if(loaded) {
      adaptedData = !!this.state.invoicesSearchTerm ? this.filterInvoices() : this.JSON_to_arrays(invoices);
    }

    const chartData = loaded && this.chartInvoices(invoices, toggleLeft);

    const left = i18n.t('common:text.amount');
    const right = i18n.t('common:text.energy');

    const onToggle = this.toggleRender;
    const the_toggle = (
      <div>
        {toggleLeft ? (
          <div id="togglePicture" style={style.aggregationsCenter}>
            <div style={style.labelToggle}>{left}</div>
            <div id="toogleElement">
              <Switch onChange={onToggle} checked={toggleLeft} />
            </div>
            <div style={style.toggle}>
              <b>{right}</b>
            </div>
          </div>
        ) : (
          <div id="togglePicture" style={style.aggregationsCenter}>
            <div style={style.labelToggle}>
              <b>{left}</b>
            </div>
            <div id="toogleElement">
              <Switch onChange={onToggle} checked={toggleLeft} />
            </div>
            <div style={style.toggle}>{right}</div>
          </div>
        )}
      </div>
    );

    const unit = !toggleLeft
      ? regional_settings.currency
      : regional_settings.energy_unit;

    const chart = (
      <Chart
        data={chartData.data}
        components={chartData.components}
        unit={unit}
        animated={true}
        stacked={true}
      />
    );

    const subTitle =
      _.get(this.props, 'filterContract.cups.full_address', false)
        ? `${i18n.t('common:text.invoices_view_subtitle')}: ${this.props.filterContract.cups.full_address}`
        : null;
    const start_items = data.offset_items;
    const end_items = Math.min(start_items + data.page_items, data.total_items);
    const dialogActions = (
        <Button
          key={'close'}
          color={"primary"}
          variant={"text"}
          onClick={this.handleCloseDialog}
        >
          {i18n.t('common:text.invoices_dialog_close')}
        </Button>
      );

    const paymentDialog = (
      <Dialog
        open={this.state.dialogOpen || false}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.handleCloseDialog()
          }
        }}
        scroll={"body"}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography variant={"h4"}>{i18n.t('common:text.invoices_dialog_title')}</Typography>
          </Box>
          <table className="table">
            <thead>
              <tr>
                <th>{i18n.t('common:text.invoices_dialog_table_date')}</th>
                <th>{i18n.t('common:text.invoices_dialog_table_description')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dialogInvoice &&
                this.state.dialogInvoice.pending_state_history.map(
                  (i, index) => (
                    <tr key={index}>
                      <td>{dayjs(i.change_date).format("L")}</td>
                      <td>{i.pending_state.ov_description}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    );

    return (
      <div>
        <Notification
          message={this.props.message_text}
          time={6000}
          open={true}
        />

        {paymentDialog}

        <ContentHeader
          title={i18n.t('common:text.invoices_view_title')}
          addButton={false}
          refreshButton={true}
          refreshClickMethod={() => this.refreshData()}
        />
        {this.props.loaded ? (
          <div style={style.table}>
            {
              //if subtitle
              { subTitle } && (
                <Box m={1}>
                  <Typography variant={"h5"}>{subTitle}</Typography>
                </Box>
              )
            }

            {invoices.length > 0 ? (
              <div>
                <Box mb={2} ml={1}>
                  <Typography variant={"h6"}>
                    {i18n.t('common:text.invoices_contracts_chart')}
                  </Typography>
                </Box>
                <div>{the_toggle}</div>

                <div style={style.chart}>{chart}</div>
                <Grid item sm={12} md={6}>
                  <Tooltip title={i18n.t('common:text.invoices_filter_tooltip')} placement="right">
                    <TextField
                      id="search"
                      type="search"
                      label={i18n.t('common:text.invoices_filter')}
                      variant="outlined"
                      style={style.searchInvoices}
                      value={this.state.invoicesSearchTerm}
                      fullWidth={true}
                      onChange={(event) => this.handleChangeSearchTerm(event, adaptedData)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                </Grid>

                <SmartTable
                  header={adaptedData.header}
                  data={adaptedData.content}
                />
              </div>
            ) : (
              <Box mb={2} ml={1}>
                <Typography variant={"h5"}>
                  {i18n.t('common:text.invoices_view_empty_list')}
                </Typography>
              </Box>
            )}
          </div>
        ) : (
          <div>
            <LoadingAnimation />
          </div>
        )}
        {data.page_items < data.total_items && (
          <div style={{ textAlign: "center" }}>
            {start_items > 0 && (
              <Button
                variant={"text"}
                onClick={() => this.previous_page()}
                disabled={data.isFetching}
              >
                {<ArrowBack />}
                {"Anterior"}
              </Button>
            )}
            <span>
              Mostrando de {start_items} a {end_items} de {data.total_items}
            </span>
            {end_items < data.total_items && (
              <Button
                variant={"text"}
                onClick={() => this.next_page()}
                disabled={data.isFetching}
              >
                {"Siguiente"}
                {<ArrowForward />}
              </Button>
            )}
          </div>
        )}
        {debug(invoices)}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

InvoicesView.propTypes = {
  fetchInvoices: PropTypes.func,
  loaded: PropTypes.bool,
  data: PropTypes.any,
  token: PropTypes.string,
};
